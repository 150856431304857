module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kontent-gatsby-template","short_name":"kc-gatsby","start_url":"/","background_color":"#0087DC","theme_color":"#0087DC","display":"minimal-ui","icon":"src/assets/images/kentico-kontent.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"26e65cd9a42ab2c9ad2a13a81f2fce1d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
